<template>
  <div
    v-loading="loading"
    class="w-full flex flex-1 flex-col"
  >
    <!-- Header -->
    <div class="flex justify-between items-center pb-8 border-b border-gray-200">
      <h3 class="text-base md:text-20 leading-tight font-bold font-heading text-gray-800 truncate pr-16">
        Add post
      </h3>

      <!-- Action buttons -->
      <div class="flex-shrink-0">
        <AppButton
          variant="primary"
          size="standart-sm"
          class="mr-10"
          @click="generateDMOPost"
        >
          <span class="hidden md:inline-block">
            Generare "Daily Market Overview" post
          </span>
          <span class="inline-block md:hidden">
            Gen. Daily post
          </span>
          <i class="icon-plus text-12 ml-8" />
        </AppButton>

        <AppButton
          :to="{ name: routeNames.posts }"
          variant="danger"
          size="standart-sm"
        >
          Cancel
          <i class="icon-cross text-12 ml-8" />
        </AppButton>
      </div>
    </div>

    <div class="w-full flex pt-16 flex-1 overflow-y-auto">
      <Form
        :validation-schema="postCreateAndPublishSchema"
        autocomplete="off"
        class="w-full px-4"
        @submit="onSubmitForm"
      >
        <div class="w-full">
          <p class="font-medium text-left mb-16 text-blue-600 whitespace-normal">Post title</p>
          <div class="w-full flex flex-wrap">
            <div class="w-full">
              <AppInput
                id="title"
                v-model="postModel.title"
                type="text"
                name="title"
                label="Title"
                placeholder="Some text here"
                autofocus
                required
                textarea
              />
            </div>
          </div>
        </div>

        <div class="w-full">
          <p class="font-medium text-left mb-16 text-blue-600 whitespace-normal">Post thumbnail URL</p>

          <div
            v-if="postModel.thumbnailUrl"
            class="w-full text-center"
          >
            <img
              :src="postModel.thumbnailUrl"
              class="max-h-256 mx-auto"
            >
          </div>

          <div class="w-full flex flex-wrap">
            <AppFileUpload
              id="thumbnailUrl"
              ref="appFileUpload"
              label="Post thumbnail"
            >
              <template #infoText>
                Please, upload post thumbnail!
              </template>
            </AppFileUpload>
          </div>
        </div>

        <div class="w-full">
          <p class="font-medium text-left mb-16 text-blue-600 whitespace-normal">Post content</p>
          <div class="w-full flex flex-wrap">
            <div class="w-full">
              <AppInput
                id="content"
                v-model="postModel.content"
                type="text"
                name="content"
                label="Content"
                placeholder="Some text"
                required
                textarea
              />
            </div>
          </div>
        </div>

        <div class="w-full">
          <p class="font-medium text-left mb-16 text-blue-600 whitespace-normal">Social media content</p>
          <div class="w-full flex flex-wrap">
            <div class="w-full">
              <AppInput
                id="telegram"
                v-model="postModel.telegram"
                type="text"
                name="telegram"
                label="Telegram content"
                placeholder="Some text here..."
                required
                textarea
              />
            </div>
          </div>

          <div class="w-full flex flex-wrap">
            <div class="w-full">
              <AppInput
                id="twitter"
                v-model="postModel.twitter"
                type="text"
                name="twitter"
                label="Twitter content"
                placeholder="Some text here..."
                required
                textarea
              />
            </div>
          </div>

          <div class="w-full flex flex-wrap">
            <div class="w-full">
              <AppInput
                id="instagram"
                v-model="postModel.instagram"
                type="text"
                name="instagram"
                label="Instagram content"
                placeholder="Some text here..."
                required
                textarea
              />
            </div>
          </div>
        </div>

        <div class="w-full flex flex-col items-center justify-center pt-40 pb-24 md:pb-40">
          <div class="w-full md:w-1/2">
            <AppButton
              :loading="loading"
              variant="primary"
              type="submit"
              class="w-full"
            >
              Create and Publish
            </AppButton>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { Form } from 'vee-validate'
import { useRouter } from 'vue-router'

import { notification } from '@/core/helpers'
import { routeNames } from '@/router'
import { postCreateAndPublishSchema } from '@/core/schemas'
import { usePosts, useSocialSignatures } from '@/core/compositions'

import AppButton from '@/components/stateless/AppButton.vue'
import AppInput from '@/components/stateless/AppInput.vue'
import AppFileUpload from '@/components/stateless/AppFileUpload.vue'

export default defineComponent({
  name: 'EditResource',

  components: {
    Form,
    AppButton,
    AppInput,
    AppFileUpload
  },

  setup () {
    const router = useRouter()

    const { postModel, createAndPublishPost, generateDailyMarketOverviewPost, setPostModel } = usePosts()
    const { socialSignatures, getSocialSignatures } = useSocialSignatures()

    const loading = ref(true)
    const appFileUpload = ref(null)

    onMounted(() => {
      getInitialData()
    })

    const wireSocialSignaturesWithPostLabels = () => {
      socialSignatures.value.forEach(s => {
        postModel[s.platform] = postModel[s.platform] ? (postModel[s.platform] + s.signature) : s.signature
      })
    }

    const getInitialData = async () => {
      loading.value = true

      try {
        await Promise.all([
          getSocialSignatures({ page: 1, size: 100 })
        ])

        wireSocialSignaturesWithPostLabels()
      } catch (e) {
        console.error(e)
        notification()
      } finally {
        loading.value = false
      }
    }

    const onSubmitForm = async () => {
      const isThumbnailAttached = appFileUpload.value.isFileSelected
      if (!isThumbnailAttached && !postModel.thumbnailUrl) {
        notification({ title: 'Please, select post thumbnail!' })
        return
      }

      loading.value = true

      try {
        const res = await appFileUpload.value.submitUpload()
        if (res?.url) {
          postModel.thumbnailUrl = res.url
        }

        await createAndPublishPost()

        notification({ type: 'success', title: 'Success!', message: 'Post successfully published!' })

        router.push({ name: routeNames.posts })
      } catch (err) {
        console.log(err)
        notification({ title: 'Post publishing error!', errorCode: err.error.code })
      } finally {
        loading.value = false
      }
    }

    const generateDMOPost = async () => {
      loading.value = true

      try {
        const result = await generateDailyMarketOverviewPost()

        setPostModel({
          title: 'Daily Market Overview',
          thumbnailUrl: process.env.VUE_APP_DAILY_MARKET_OVERVIEW_POST_THUMBNAIL,
          content: result.instagram,
          telegram: result.telegram,
          twitter: result.twitter,
          instagram: result.instagram
        })

        wireSocialSignaturesWithPostLabels()

        notification({ type: 'success', title: 'Success!', message: 'Daily Market Overview Post successfully generated!' })
      } catch (err) {
        console.log(err)
        notification({ title: 'Post generation error!', errorCode: err.error.code })
      } finally {
        loading.value = false
      }
    }

    return {
      routeNames,
      postCreateAndPublishSchema,
      loading,
      onSubmitForm,
      postModel,
      appFileUpload,
      generateDMOPost
    }
  }
})
</script>
